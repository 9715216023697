import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionNeuro({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section "} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.title}>Neurogene taal-spraakstoornissen</h2>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <h3 className={classes.title}>Samengevat</h3>
            <p>
              In onze praktijk kunnen personen terecht met spraak- en taalproblemen na een niet-aangeboren hersenletsel, zoals afasie, dysartrie en apraxie. De therapie kan plaatsvinden in de praktijk, aan huis of in het woonzorgcentrum.
            </p>
            <p>
              Spraak- en slikproblemen, ook wel dysartrie en dysfagie genoemd, ten gevolge van neurodegeneratieve aandoeningen kunnen de levenskwaliteit van een persoon sterk beïnvloeden. Wij begeleiden deze patiënten in de praktijk of aan huis.
            </p>
          </GridItem>

          <GridItem md={8} sm={8}>
            <h3 className={classes.title}></h3>
            <h4 className={classes.title}>Neurogene stoornissen</h4>
            <p>
              Personen met een niet-aangeboren hersenletsel (zoals een beroerte, hersenbloeding of tumor) kunnen spraak- en taalproblemen ontwikkelen. 
              Afhankelijk van de locatie, aard en omvang van het letsel kunnen verschillende stoornissen optreden, alleen of in combinatie, en in uiteenlopende ernst.
            </p>
            <p>
              <ul>
                <li>
                  <b>Afasie</b>: Problemen met taalbegrip en/of taalproductie, vaak gepaard met woordvindingsmoeilijkheden.
                </li>
                <li>
                  <b>Dysartrie</b>: Een spraakstoornis die kan leiden tot slappe of onnauwkeurige articulatie, afwijkend spreektempo, onaangepaste klemtonen of luidheid, wat de verstaanbaarheid vermindert.
                </li>
                <li>
                  <b>Spraakapraxie</b>: Moeite met het plannen van spraakklanken, vaak zichtbaar in ‘zoekgedrag’ en inconsistente fouten tijdens het spreken.
                </li>
              </ul>
            </p>
            <h4 className={classes.title}>Neurodegeneratieve aandoeningen</h4>
            <p>
              Neurodegeneratieve aandoeningen zoals Parkinson, ALS, MS, MSA en PSP tasten het zenuwstelsel aan, wat kan leiden tot spraak- en slikproblemen. De logopedist brengt deze problemen in kaart, waarbij de klachten sterk kunnen variëren per persoon.
            </p>
            <p>
              <b>Dementie</b> is een overkoepelende term voor stoornissen die het cognitieve functioneren aantasten. Naast geheugenstoornissen kunnen ook veranderingen in stemming, persoonlijkheid, gedrag en communicatie voorkomen. Vooral bij FTD (frontotemporale dementie) staan taalproblemen op de voorgrond. Meer informatie is beschikbaar op de website van het Expertisecentrum Dementie Vlaanderen: <a href="https://dementie.be" target="_blank"><b>dementie.be</b></a>.
            </p>

            <h4 className={classes.title}>Therapie</h4>
            <h6 className={classes.title}>Neurogene stoornissen</h6>
            <p>
              Neurogene stoornissen verstoren de communicatie tussen de patiënt en zijn omgeving. Therapie richt zich op herstel van deze communicatie en het vergroten van de zelfstandigheid. De prognose hangt af van de aard, grootte en locatie van het letsel, evenals de leeftijd van de patiënt; jongere hersenen herstellen sneller door hun grotere plasticiteit.
            </p>
            <p>
              Behandeling is het meest effectief kort na het ontstaan van het letsel en bestaat uit revalidatie, consolidatie en compensatie. Eerst worden verloren functies hersteld met specifieke technieken, die vervolgens worden ingeslepen voor dagelijks gebruik. Als herstel beperkt is, of de communicatie is tijdelijk zeer ernstig aangetast, kunnen compenserende hulpmiddelen worden ingezet. De logopedist helpt bij het vinden van het meest geschikte communicatiehulpmiddel, ook wel ondersteunde communicatie genoemd.
            </p>

            <h6 className={classes.title}>Neurodegeneratieve aandoeningen</h6>
            <p>
              Therapie richt zich op het behoud van spraak- en slikfuncties door wetenschappelijk onderbouwde technieken, zoals LSVT voor Parkinsonpatiënten. Door het progressieve karakter van deze aandoeningen nemen de klachten vaak toe. Bij een hoog verslikkingsrisico worden compenserende technieken toegepast, zoals het indikken van vloeistoffen, om veilig slikken te waarborgen. Bij ernstige spraakproblemen worden alternatieve communicatietechnieken ingezet om de communicatie te behouden. Het doel is de levenskwaliteit en zelfstandigheid van de patiënt zo lang mogelijk te optimaliseren.
            </p>
            <p>
            Bij dementie ligt de nadruk op psycho-educatie, het aanleren van compenserende strategieën en communicatie-coaching voor de omgeving. Ondersteunde communicatie kan ook hier een waardevolle rol spelen.
            </p>

          </GridItem>

          <GridItem md={12} sm={12}>
            <h3 className={classes.title}>Therapeuten</h3>
            <p>
              Voor neurogene taal-spraakstoornissen en neurodegeneratieve aandoeningen zal u onderzocht en behandeld worden
              door <a href="team#madou"><b>Madou Parys</b></a>.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  </div>
  )
};
