import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionLearning({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.titleWhite}>Leerstoornissen</h2>
          <GridContainer className={classes.descriptionWhite}>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
              Kinderen kunnen moeite hebben met lezen, schrijven en rekenen. Als ze achterstand oplopen in één of meer van deze schoolse vaardigheden, kan een logopedist ondersteuning bieden. Blijven de problemen bestaan ondanks intensieve begeleiding, dan kan er sprake zijn van een leerstoornis. Dit noemen we dyslexie bij lees- en/of spellingsproblemen en dyscalculie bij rekenproblemen.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Wat zijn leerstoornissen?</h4>
              <h6 className={classes.title}>Dyslexie</h6>
              <p>
              Leerlingen met dyslexie vertonen opvallende en blijvende moeilijkheden met lezen en/of spelling . De fouten die zij maken lijken op verstrooidheidsfouten. Bijvoorbeeld: ‘kineren’ in plaats van ‘kinderen’ schrijven. Zij lezen hun vragen dikwijls verkeerd en antwoorden dan ook fout of onvolledig. Bij vreemde talen kan dit ook voor heel wat problemen zorgen.
              </p>
              <h6 className={classes.title}>Dyscalculie</h6>
              <p>
              Leerlingen met dyscalculie vertonen opvallende en blijvende moeilijkheden met rekenen en wiskunde en dit op het vlak van automatiseren (geheugendyscalculie), vaardigheden en technieken (procedurele dyscalculie) en/of problemen met visuo-spatiële deeltaken van het rekenen (visuo-motorische dyscalculie). Dyscalculie kan samengaan met andere leerproblemen als ADHD en dyslexie.
              </p>

              <h4 className={classes.title}>Therapie</h4>
              <p>
              Bij de behandeling van leerstoornissen wordt gezocht naar de methode die op lange termijn het beste resultaat oplevert voor het kind. Hierbij kan bijvoorbeeld gebruik worden gemaakt van Eureka-onderwijsmethoden, specifiek gericht op kinderen met leerstoornissen, zoals "Rekentrappers" (met onder andere cirkelrekenen) of "Als spelling een kwelling is." Daarnaast kunnen gepersonaliseerde (tijdelijke of permanente) hulpmiddelen worden ingeschakeld om het kind op korte en lange termijn meer leerkansen en succeservaringen te bieden op school. Het doel is de zelfredzaamheid van de leerling te vergroten. 
              </p>

              <h4 className={classes.title}>Attest</h4>
              <p>
              Indien de problemen met lezen, spelling en/of rekenen blijven bestaan ondanks intensieve begeleiding, kan er een attest voor dyslexie of dyscalculie worden opgesteld. Dit attest wordt pas opgemaakt na ongeveer 12 maanden intensieve begeleiding in overleg met de ouders, de school en het CLB. Hierbij worden verschillende ontwikkelingsdomeinen in kaart gebracht om andere oorzaken uit te sluiten. Zo kan de behandeling verder afgestemd worden op de individuele noden van het kind.
              </p>

            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor leerstoornissen zal u onderzocht en behandeld worden
                door <a href="team#lore"><b>Lore Van de Kelft</b></a> en <a href="team#madou"><b>Madou Parys</b></a>.
              </p>
            </GridItem>
          </GridContainer>
      </div>
    </div>
  </div>
  )
};
