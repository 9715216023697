import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionClutter({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section "} {...rest} >
      <div className={classes.targetContent}>
        <div className={classes.container}>
          <h2 className={classes.title}>Broddelen</h2>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
              Broddelen is een vloeiendheidsstoornis waarbij iemand te snel praat, wat leidt tot veel onvloeiendheden en taalfouten, zoals verkeerd geplaatste woorden of grammaticale fouten. Andere kenmerken zijn mompelende en stillere spraak, wat de verstaanbaarheid beperkt. 
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Wat is broddelen?</h4>
              <p>
              Broddelen is een vloeiendheidsstoornis waarbij iemand moeite heeft om het spreektempo aan te passen aan de taalcomplexiteit. Dit leidt vaak tot een hoog spreektempo, waarbij pauzes op onjuiste plaatsen vallen en de verstaanbaarheid vermindert door weglating, omwisseling of oppervlakkige articulatie van lettergrepen. Ook zijn zinnen soms onjuist geformuleerd. Zuiver broddelen komt voor bij ongeveer 5% van de bevolking.
              </p>

              <h4 className={classes.title}>Therapie</h4>
              <p>
              Een persoon die broddelt is zich vaak niet bewust van zijn eigen onvloeiendheden, waardoor het wijzigen van het eigen spreken moeilijk is. De therapie zal bestaan uit het creëren van bewustzijn rond de taal- of spraakmoeilijkheden en vervolgens het vertragen van het spreektempo. Dit wordt geoefend op verschillende taalniveaus.
              </p>

            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor broddelen zal u onderzocht en behandeld worden door <a href="team#elke"><b>Elke Ven</b></a>.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
};
